import { createSlice } from "@reduxjs/toolkit"

export const SearchSlice = createSlice({
  name: "SearchSlice",
  initialState: {
    result: [],
    searchQuery: "",
  },
  reducers: {
    saveSearch: (state, actions) => {
      state.result = actions.payload
    },
    clearSearch: state => {
      state.result = []
    },
    saveQuery: (state, action) => {
      state.searchQuery = action.payload
    },
  },
})

export const { saveSearch, clearSearch, saveQuery } = SearchSlice.actions
export default SearchSlice.reducer
