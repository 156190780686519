import {
  useState as state,
  useEffect as Effect,
  useMemo as Memo,
  useCallback as CallBack,
  useRef as RefUse,
  Suspense as Usesuspense,
  createContext as creatcontext,
} from "react"

export const useState = state
export const useEffect = Effect
export const useMemo = Memo
export const useCallback = CallBack
export const useRef = RefUse
export const Suspense = Usesuspense
export const createContext = creatcontext
