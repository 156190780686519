import { configureStore, combineReducers } from "@reduxjs/toolkit"
import StateSlice from "./slices/states"
import Page from "./slices/pages"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"
import SearchSlice from "./slices/search"
import ModalSlice from "./slices/modalSlice"

const persistConfig = {
  key: "root",
  storage,
}

const rootReducer = combineReducers({
  state: persistReducer(persistConfig, StateSlice),
  page: persistReducer(persistConfig, Page),
  search: persistReducer(persistConfig, SearchSlice),
  modal: ModalSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
})

export default store
