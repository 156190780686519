import {
  createContext,
  Suspense,
  useEffect,
  useState,
} from "../hooks/stateHooks"
import React from "../hooks/reactImport"
import { useDispatch } from "react-redux"
import { setCaptchaResponse } from "../redux/slices/states"

export const PageStateContext = createContext()

export const ProviderAPI = ({ children, value }) => {
  const [state, setState] = useState(value)

  return (
    <PageStateContext.Provider value={{ state, setState }}>
      {children}
    </PageStateContext.Provider>
  )
}

export const GoogleRecaptchContext = createContext(null)
export default function RecaptchaContext({ children, captchaSiteKey }) {
  const dispatch = useDispatch()
  // var verifyCallba = function (response) {
  //   dispatch(setCaptchaResponse(response))
  // }

  const handleLoaded = _ => {
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute(captchaSiteKey, { action: "homepage" })
        .then(token => {
          dispatch(setCaptchaResponse(token))
        })
    })
  }

  useEffect(() => {
    // Add reCaptcha
    let timer
    if (captchaSiteKey) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${captchaSiteKey}`
        script.addEventListener("load", handleLoaded)
        document.body.appendChild(script)
      }, 15000)
    }

    return () => clearTimeout(timer)
  }, [captchaSiteKey])
  return (
    <Suspense>
      {/* {captchaSiteKey && load ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={captchaSiteKey}
          scriptProps={{
            async: false, // optional, default to false,
            defer: true, // optional, default to false
            appendTo: "head", // optional, default to "head", can be "head" or "body",
            nonce: undefined, // optional, default undefined
          }}
        >
          <GoogleReCaptcha onVerify={verifyCallba} />
          <GoogleRecaptchContext.Provider>
            {children}
          </GoogleRecaptchContext.Provider>
        </GoogleReCaptchaProvider>
      ) : ( */}
      <GoogleRecaptchContext.Provider value={captchaSiteKey}>
        {children}
      </GoogleRecaptchContext.Provider>
      {/* )} */}
    </Suspense>
  )
}

// const PageStateContext = createContext(null)
// export default PageStateContext
