import { createSlice } from "@reduxjs/toolkit"

export const StateSlice = createSlice({
  name: "stateSlice",
  initialState: {
    defaultModalState: false,
    defaultDrawerState: false,
    defaultMegaMenuState: false,
    defaultSubMenuState: false,
    MenuType: {
      type: "Standard",
      column: "",
    },
    modal: [],
    drawers: [],
    megaMenus: [],
    subMenus: [],
    drawerId: "",
    keepLogin: "",
    clientName: "",
    urls: [],
    player: "",
    videoId: [],
    forms: [],
    captchaResponse: "",
    amount: "",
    emailAddress: "",
    notifyText: "",
    capthaLoaded: false,
  },
  reducers: {
    setDefaultModalState: (state, action) => {
      state.defaultModalState = action.payload
    },
    setDefaultDrawer: (state, action) => {
      state.defaultDrawerState = action.payload
    },
    setDefaultMegaMenu: (state, action) => {
      state.defaultMegaMenuState = action.payload
    },
    setDefaultSubMenu: (state, action) => {
      state.defaultSubMenuState = action.payload
    },
    SetMenuType: (state, action) => {
      state.MenuType.type = action.payload.type
      state.MenuType.column = action.payload.column
    },
    setModals: (state, action) => {
      const checkModal = state.modal.find(
        c => c.Id === action.payload.Id || action.payload
      )

      const data = {
        ...action.payload,
        touched: true,
      }
      state.modal = [...state.modal, data]
    },
    // CloseToggleModal: (state, action) => {
    //   const modalValue = state.modal.find(item => item.Id === action.payload)
    //   modalValue.value = false
    // },
    // OpenToggleModal: (state, action) => {
    //   const modalValue = state.modal.find(item => item.Id === action.payload)
    //   if (modalValue) {
    //     modalValue.value = true
    //     modalValue.touched = true
    //   } else {
    //     const data = {
    //       Id: action.payload,
    //       value: true,
    //       touched: true,
    //     }

    //     state.modal = [...state.modal, data]
    //   }
    // },
    SetDrawers: (state, action) => {
      state.drawers = [...state.drawers, action.payload]
    },
    // CloseToggleDrawer: (state, action) => {
    //   const drawerValue = state.drawers.find(item => item.Id === action.payload)
    //   if (drawerValue) {
    //     drawerValue.value = false
    //   }
    // },
    // OpenToggleDrawer: (state, action) => {
    //   const drawerValue = state.drawers.find(item => item.Id === action.payload)
    //   if (drawerValue) {
    //     drawerValue.value = true
    //   }
    // },
    setMegaMenus: (state, action) => {
      state.megaMenus = [...state.megaMenus, action.payload]
    },
    setSubMenus: (state, action) => {
      state.subMenus = [...state.subMenus, action.payload]
    },
    OpenMegaMenu: (state, action) => {
      const menus = state.megaMenus.find(item => item.Id === action.payload)
      menus.value = !state.megaMenus.find(item => item.Id === action.payload)
        .value
    },
    OpenSubMenu: (state, action) => {
      const submenus = state.subMenus.find(item => item.Id === action.payload)
      submenus.value = !state.subMenus.find(item => item.Id === action.payload)
        .value
    },
    CloseMegaMenus: (state, action) => {
      const menus = state.megaMenus.find(item => item.Id === action.payload)
      menus.value = false
    },
    setDrawerId: (state, action) => {
      state.drawerId = action.payload
    },
    setKeepLogin: (state, action) => {
      state.keepLogin = action.payload
    },
    setIsLogin: (state, action) => {
      state.isLogin = action.payload.value
    },
    setClientName: (state, action) => {
      state.clientName = action.payload
    },
    setVideoUrls: (state, action) => {
      state.urls = [...state.urls, action.payload]
    },
    setPlayVideo: (state, action) => {
      state.player = action.payload
    },
    setVideoIds: (state, action) => {
      state.videoId = [...state.videoId, action.payload]
    },
    setForms: (state, action) => {
      state.forms = [...state.forms, action.payload]
    },
    ToggleForm: (state, action) => {
      const modalValue = state.forms.find(item => item.Id === action.payload)
      if (modalValue) {
        modalValue.show = !modalValue.show
      }
    },
    setCaptchaResponse: (state, action) => {
      state.captchaResponse = action.payload
    },
    setAmount: (state, action) => {
      state.amount = action.payload
    },

    setEmail: (state, action) => {
      state.emailAddress = action.payload
    },
    setNotifyText: (state, action) => {
      state.notifyText = action.payload
    },
    clearNotifyText: state => {
      state.notifyText = ""
    },
    setCapthaLoaded: (state, action) => {
      state.capthaLoaded = action.payload
    },
  },
})

export const {
  defaultDrawerState,
  setDefaultModalState,
  setDefaultDrawer,
  setDefaultMegaMenu,
  setDefaultSubMenu,
  SetMenuType,
  setModals,
  // CloseToggleModal,
  // OpenToggleModal,
  SetDrawers,
  // CloseToggleDrawer,
  // OpenToggleDrawer,
  setMegaMenus,
  setSubMenus,
  OpenMegaMenu,
  OpenSubMenu,
  CloseMegaMenus,
  setDrawerId,
  setKeepLogin,
  setIsLogin,
  setClientName,
  setVideoUrls,
  setPlayVideo,
  setVideoIds,
  setForms,
  ToggleForm,
  setCaptchaResponse,
  setAmount,
  setEmail,
  setNotifyText,
  clearNotifyText,
  setCapthaLoaded,
} = StateSlice.actions
export default StateSlice.reducer
