import { createSlice } from "@reduxjs/toolkit"

export const ModalSlice = createSlice({
  name: "SearchSlice",
  initialState: {
    modal: [],
    drawers: [],
  },
  reducers: {
    CloseToggleModal: (state, action) => {
      const modalValue = state.modal.find(item => item.Id === action.payload)
      modalValue.value = false
      modalValue.touched = false
    },
    OpenToggleModal: (state, action) => {
      const modalValue = state.modal.find(item => item.Id === action.payload)
      if (modalValue) {
        modalValue.value = true
        modalValue.touched = true
      } else {
        const data = {
          Id: action.payload,
          value: true,
          touched: true,
        }

        state.modal = [...state.modal, data]
      }
    },
    CloseToggleDrawer: (state, action) => {
      const drawerValue = state.drawers.find(item => item.Id === action.payload)
      if (drawerValue) {
        drawerValue.value = false
        drawerValue.touched = false
      }
    },
    OpenToggleDrawer: (state, action) => {
      const drawerValue = state.drawers.find(item => item.Id === action.payload)
      if (drawerValue) {
        drawerValue.value = true
        drawerValue.touched = true
      } else {
        const data = {
          Id: action.payload,
          value: true,
          touched: true,
        }

        state.drawers = [...state.drawers, data]
      }
    },
  },
})

export const {
  CloseToggleModal,
  OpenToggleModal,
  CloseToggleDrawer,
  OpenToggleDrawer,
} = ModalSlice.actions
export default ModalSlice.reducer
